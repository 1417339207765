<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 150vh;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ $t(`Shipment: ${this.request.id} | ${index}/${all}`) }}
        </div>

        <q-space />

        <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
            @click="close"
        />
      </q-card-section>

      <q-card-section >
        <form-builder  ref="formBuilder" :schema="schema"/>
      </q-card-section>

      <q-card-section class="row justify-end" style="border-bottom: 1px solid #ccc;">
        <q-btn
          color="light-blue-9"
          text-color="white"
          size="sm"
          :label="$t('Save')"
          no-caps
          unelevated
          @click="saveSenderAndCarrier"
        />
      </q-card-section>


      <q-card-section>
        <form-builder :schema="schema2"/>
      </q-card-section>

      <q-card-section class="row justify-end">
        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            size="sm"
            :label="$t('Export')"
            no-caps
            unelevated
            @click="saveTaskDimensions"
        />

        <q-btn
          color="dark"
          text-color="white"
          v-close-popup
          size="sm"
          :label="$t('Next')"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DeliveryRequestExport',
  emits: ['popup-closed'],
  data () {
    return {
      request: null,
      isOpen: false,
      errors: [],
      index: null,
      all: null,
      modelSender: {
        deliveryService: null,
        rate: null
      },
      model: {
        weight: 100,
        dimensions: {
          x: 100,
          y: 100,
          z: 100
        }
      },
      isEnabledSenderFilter: true,
      isLoadDeliveryServicesIds: false,
      deliveryServicesIDs: [],
      country: null
    }
  },
    computed: {
    ...mapGetters([
      'settings',
      'appOptions'
    ]),
    schema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            id: 'drForm',
            styleClasses: 'col',
            fields: [
              {
                ref: 'deliveryService',
                type: 'multiselect',
                label: this.$t('Shipping carrier'),
                wrapperStyleClasses: 'row col-12 col-md-4 q-pa-xs',
                field: 'deliveryService',
                value: this.modelSender.deliveryService,
                required: true,
                disabled:  !this.request || !this.request._embedded.sender,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row.logo) {
                    return ''
                  }
                  if (row.logo.includes('http')) {
                    return `${row.logo}`
                  } else {
                    return `${this.appOptions.defaultServer}${row.logo}`
                  }

                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'name', direction: 'desc' }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.country && this.isEnabledSenderFilter) {
                    query.filter.push({ type: 'eq', field: 'country', value: this.country })
                  }

                  return this.$service.deliveryService.getAll(query)
                },
                onChange: (deliveryService) => {
                    this.modelSender.deliveryService = deliveryService
                    this.modelSender.rate = null
                    this.resetRateField()
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'showOrHide',
                    label: this.isEnabledSenderFilter
                      ? this.$t('Show for all')
                      : this.$t('Hide invalid'),
                    color: 'dark',
                    styleClasses: 'fit',
                    onClick: () => {
                      this.isEnabledSenderFilter = !this.isEnabledSenderFilter
                      this.modelSender.rate = null
                      this.modelSender.deliveryService = null
                      this.resetDeliveryServiceField()
                      this.resetRateField()
                    }
                  }
                ]
              },
              {
                ref: 'rate',
                type: 'multiselect',
                label: this.$t('Shipping rate'),
                wrapperStyleClasses: 'row q-pa-xs',
                field: 'rate',
                value: this.modelSender.rate,
                required: true,
                disabled: !this.request ||
                    !this.request._embedded.sender,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row._embedded?.deliveryService?.logo) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  query['order-by'] = [
                    { type: 'field', field: 'deliveryService', direction: 'desc' },
                    { type: 'field', field: 'name', direction: 'desc' }
                  ]


                  query.filter.push({ type: 'eq', field: 'deliveryServices', value: this.request._embedded.deliveryService.id })


                  return this.$service.deliveryServiceRate.getAll(query)
                },
                onChange: (rate) => {
                    this.modelSender.rate = rate
                  // const types = ['service_point', 'self_service_point', 'simple']
                  // this.isDisabledServicePoints = !types.includes((rate || {}).type)
                  //
                  // this.updateDeliveryRequestEmbedded({ rate, servicePoint: null })

                }
              },
            ],
          }
        ]
      }
    },
    schema2 () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'number',
                label: this.$t(`Weight (${this.appOptions.defaultDimensions.weightUnit})`),
                field: 'weight',
                value: this.model.weight,
                required: true,
                wrapperStyleClasses: 'col-3 q-pa-xs',
                onChange: (value) => {
                  this.model.weight = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'x',
                value: this.model.dimensions.x,
                required: true,
                label: this.$t(`Width (${this.appOptions.defaultDimensions.dimensionUnit})`),
                wrapperStyleClasses: 'col-3 q-pa-xs',
                onChange: (value) => {
                  this.model.dimensions.x = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'y',
                value: this.model.dimensions.y,
                required: true,
                label: this.$t(`Height (${this.appOptions.defaultDimensions.dimensionUnit})`),
                wrapperStyleClasses: 'col-3 q-pa-xs',
                onChange: (value) => {
                  this.model.dimensions.y = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'z',
                value: this.model.dimensions.z,
                required: true,
                label: this.$t(`Length (${this.appOptions.defaultDimensions.dimensionUnit})`),
                wrapperStyleClasses: 'col-3 q-pa-xs',
                onChange: (value) => {
                  this.model.dimensions.z = value
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateDeliveryRequest',
      'setMode',
      'addErrorNotification',
      'addWarningNotification'
    ]),
    ...mapActions([
      'loadDeliveryRequestByBarcode'
    ]),
    getFormRef () {
        return Array.isArray(this.$refs.formBuilder.$refs.drForm)
            ? this.$refs.formBuilder.$refs.drForm[0].$refs
            : this.$refs.formBuilder.$refs.drForm.refs
    },
    getDeliveryServiceRef () {
        return this.getFormRef().deliveryService[0].$refs.deliveryService
    },
    resetDeliveryServiceField () {
        this.getDeliveryServiceRef().reset()
    },
    resetRateField () {
        this.getRateRef().reset()
    },
    getRateRef () {
        return this.getFormRef().rate[0].$refs.rate
    },
    saveSenderAndCarrier () {
      if(this.modelSender.deliveryService && !this.modelSender.rate) {
          this.addErrorNotification('Please select rate!')
          return
      }
      if (this.request && this.request._embedded && this.request._embedded.deliveryService && this.modelSender.deliveryService.id === this.request._embedded.deliveryService.id  &&  this.request._embedded.rate && this.modelSender.rate.id === this.request._embedded.rate.id) {
          this.addWarningNotification('No changes found')
          return
      }

      if (this.request && this.request._embedded && this.request._embedded.deliveryService && this.modelSender.deliveryService.id === this.request._embedded.deliveryService.id && this.modelSender.rate) {
          const header =  { 'X-Entity-Updated': this.request.updated }

          return this.$service.deliveryRequest.save({rate: this.modelSender.rate.id}, this.request.id, header)
      }
      const header =  { 'X-Entity-Updated': this.request.updated }

      return this.$service.deliveryRequest.save({ deliveryService: this.modelSender.deliveryService.id, rate: this.modelSender.rate.id}, this.request.id, header)
    },
    saveTaskDimensions () {
      return this.$service.deliveryRequestProcess.processByID(this.request.id, [], this.model)
    },
    loadDimensions () {
      const { weight, width, length, height } = this.settings.default

      if (!this.request.weight) {
        this.model.weight = weight
        this.errors.push('Shipment don\' have weight. Defaults will be used.')
      } else {
        this.model.weight = this.request.weight
      }

      if (!this.request.dimensions) {
        this.errors.push('Shipment don\' have dimensions. Defaults will be used.')
        this.model.dimensions = {
          x: width,
          y: height,
          z: length
        }
      } else {
        this.model.dimensions = this.request.dimensions
      }
    },
    loadDeliveryServicesByAddress (value) {
      this.isLoadDeliveryServicesIds = true
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'eq', field: 'profile', value }
        ]
      }

      return this.$service.address.getAll(query)
        .then(({ items, totalItems }) => {
          if (totalItems > 0) {
            if (!items[0]._embedded.locality) {
              this.deliveryServicesIDs = []
              this.isLoadDeliveryServicesIds = false
              return
            }

            this.country = items[0]._embedded.locality._embedded.country._links.self.href.split('/').pop()
            return this.getDeliveryServicesByCountry(this.country)
          }

          this.isLoadDeliveryServicesIds = false
        })
    },
    extractProfileID (sender) {
        if (sender._embedded.defaultSenderProfile.id) {
            return sender._embedded.defaultSenderProfile.id
        }

        const parts = sender._embedded.defaultSenderProfile._links.self.href.split('/')
        return parts[parts.length - 1]
    },
    loadDeliveryServicesIDs (value) {
      if (!value?._embedded?.defaultSenderProfile) {
        if (value._embedded?.parent && value._embedded.parent._embedded) {
          const id = this.extractProfileID(value._embedded.parent)
          return this.loadDeliveryServicesByAddress(id)
        }

        this.deliveryServicesIDs = []
        return
      }

      const id = this.extractProfileID(value)
      return this.loadDeliveryServicesByAddress(id)
    },
    getDeliveryServicesByCountry (value) {
      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'country', value }
        ]
      }

      return this.$service.deliveryService.getAll(query)
        .then(({ items }) => {
          this.deliveryServicesIDs = items.map(({ id }) => id)
          this.isLoadDeliveryServicesIds = false
        })
    },
    open (request , index , all) {
      this.request = request
      this.index = index
      this.all = all
      this.loadDimensions()


        if(this.request && this.request._embedded.deliveryService) {
          this.modelSender.deliveryService = this.request._embedded.deliveryService
        }
        if( this.request && this.request._embedded.rate) {
          this.modelSender.rate = this.request._embedded.rate
        }
        this.isOpen = true

        if (this.request && this.request._embedded && this.request._embedded.senderAddress) {

          return this.$service.address.get(this.request._embedded.senderAddress.id)
            .then(address => {
              const parts = address._embedded.locality._embedded.country._links.self.href.split('/')
              this.country = parts[parts.length - 1]
              return this.getDeliveryServicesByCountry(parts[parts.length - 1])
            })
        }
        if (this.request && this.request._embedded && this.request._embedded.sender) {
          return this.loadDeliveryServicesIDs(this.request._embedded.sender)
        }
    },
    close () {
      this.$emit('popup-closed')
    },
    handleClose () {
      this.isOpen = false
      this.resolve(false)
    },
  }
}
</script>


