<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Shipments')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="items.length > 0 ? 'amber-6' : 'dark'"
          text-color="white"
          :label="$t('Print Labels') + `${items.length > 0 ? ` (${items.length})` : ''}`"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          :disable="items.length <= 0"
          @click="processSelectedPrintRequests"
        />

        <q-btn
            :color="items.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Export') + `${items.length > 0 ? ` (${items.length})` : ''}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="items.length <= 0"
            @click="processExportRequests"
        />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Download excel')"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          @click="downloadExcel"
        />

        <q-btn
          color="positive"
          text-color="white"
          :label="$t('Import')"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          @click="openAddDeliveryRequests"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="createNewDeliveryRequest"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
          :is-open="isOpenFilter"
          :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: deliveryRequestsStatuses
            }
          }"
          @submit="handleFiltersSubmit"
          @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section class="row q-pa-none">
        <status-filter
          class="hide-on-mobile"
          :outside-selected-status="selectedStatusFilter"
          :statuses="deliveryRequestsStatuses"
          @on-change="onStatusFilter"
        />

        <div class="col">
          <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
            :rows-per-page-label="$t('Rows per page')"
            :rows="deliveryRequests"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="deliveryRequestsLoading"
            :filter="filter"
            selection="multiple"
            v-model:selected="items"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
          >
            <template v-slot:loading>
              <q-inner-loading
                showing
                color="primary"
              />
            </template>

            <template v-slot:header-selection="props">
              <q-checkbox
                  size="xl"
                  :model-value="items.length > 0"
                  color="amber-6"
                  @click="handleSelectAll(props)"
              />
            </template>

            <template
              v-slot:body="props"
              class="clickable"
            >
              <delivery-request-row-table
                :data="props"
                :items="items"
                :row="props.row"
                :column="props.column"
                @check="handleCheck"
                @dbclick="onRowClick"
              />
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>

    <delivery-request-export ref="exportModal" @popup-closed="handlePopupClosed" />

    <delivery-request-import ref="drImport" />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import FiltersCollapse from './../../components/filters/FilterCollapse'
import StatusFilter from './../../components/filters/StatusFilter'
import DeliveryRequestRowTable from './../../components/delivery-request-row-table/DeliveryRequestRowTable'
import Search from '../../components/search/Search'
import DeliveryRequestImport from '../../components/imports/DeliveryRequestImport'

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Helpers
import deliveryRequestStatusMatrix from './../../config/DeliveryRequestMatrix'
import { buildQuery } from '../../utils/query-utils'
import DeliveryRequestExport from "@/apps/app/components/delivery-services/DeliveryRequestExport.vue";

export default {
  name: 'DMParcels',
  components: {
    DeliveryRequestExport,
    StatusFilter,
    FiltersCollapse,
    DeliveryRequestRowTable,
    Search,
    DeliveryRequestImport
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Customer'),
          name: 'customer',
          align: 'left'
        },
        {
          label: this.$t('Sender'),
          name: 'sender',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Shipping'),
          name: 'shipping',
          align: 'left'
        },
        {
          label: this.$t(''),
          name: 'actions',
          align: 'left'
        }
      ],
      isOpenFilter: false,
      activatedFields: [
        'id',
        'extId',
        'orderId',
        'clientExtId',
        'trackingNumber',
        'shippedByDocument',
        'createdByDocument',
        'recipient',
        'recipientPhone',
        'weight.from',
        'weight.to',
        'retailPrice.from',
        'retailPrice.to',
        'estimatedCost.from',
        'estimatedCost.to',
        'payment.from',
        'payment.to',
        'sendDate.from',
        'sendDate.to',
        'deliveryDate.from',
        'deliveryDate.to',
        'pickUpDate.from',
        'pickUpDate.to',
        'created.from',
        'created.to',
        'deliveryService',
        'rate',
        'servicePoint',
        'integration',
        'paymentState',
        'state',
        'sender'
      ],
      time: window.appOptions.refreshTimeout,
      deliveryRequestsStatuses: deliveryRequestStatusMatrix,
      items: [],
      currentIndex: 0,
    }
  },
  computed: {
    ...mapGetters([
      'deliveryRequests',
      'deliveryRequestsItemsNumber',
      'deliveryRequestsLoading',
      'deliveryRequestsPage'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ' + this.deliveryRequestsItemsNumber)
        : this.$t('Filter')
    },
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state'
      })

      if (status && status.values) {
        return status.values
      }

      return status
        ? [status.value]
        : []
    },
    statuses () {
      return deliveryRequestStatusMatrix.reduce((acc, group) => {
        return [...acc, ...group.buttons]
      }, [])
    },
    filters () {
      const actions = {
        gte: filter => ({ from: filter.value, to: null }),
        lte: filter => ({ from: null, to: filter.value }),
        between: filter => ({ from: filter.from, to: filter.to }),
        in: filter => (filter.values.map(id => this.statuses.find(x => x.id === id)))
      }
      return (this.serverParams.filter || []).reduce((acc, filter) => {
        const currFilterValue = typeof actions[filter.type] === 'function'
          ? actions[filter.type](filter)
          : filter.value

        return {
          ...acc,
          [filter.field]: currFilterValue
        }
      }, {})
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadDeliveryRequests',
      'printLabelByRequestByID',
      'printLabelByRequestBySize',
      'getLabels'
    ]),
    ...mapMutations([
      'setDeliveryRequest',
      'interceptItemsLoading',
      'setDeliveryRequestFromList',
      'setDeliveryRequests',
      'addErrorNotification',
    ]),
    async processSelectedPrintRequests() {
        for (const selectedItem of this.items) {
            await this.printLabelByRequestByID(selectedItem);
        }
    },
    printLabelByRequestByID(deliveryRequest) {
      return new Promise((resolve) => {
        const request = deliveryRequest;

        this.$service.deliveryServicePreprocessingTask.getByDeliveryRequest(request.id)
          .then(result => {
            if (!result) {
              return this.printLabelByRequestBySize({ pageSize: 'label' });
            }

            return this.getLabels({ id: result.id, noAutoPrint: true });
          })
          .catch(error => {
            this.addErrorNotification(error.message);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    async processExportRequests() {
      for (let i = 0; i < this.items.length; i++) {
        await this.handleExport(this.items[i] , i);
      }
    },
    async handleExport(deliveryRequest , i) {
      this.currentIndex = i + 1;

      // Open the popup and load dimensions
      this.$refs.exportModal.open(deliveryRequest , this.currentIndex, this.items.length);

      // Simulate an asynchronous operation or any necessary processing
      await this.performAsyncOperation();

      // Wait until the popup is manually closed
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.$refs.exportModal.isOpen) {
            clearInterval(interval);
            resolve();
          }
        }, 100); // Check every 100ms if the popup is closed
      });
    },
    async performAsyncOperation() {
      // Simulate an asynchronous operation
      return new Promise((resolve) => {
        setTimeout(() => {
          // Perform your asynchronous logic here
          // ...

          resolve();
        }, 1000); // Replace with the actual duration of your asynchronous operation
      });
    },
    async handlePopupClosed() {
      console.log('Popup closed');
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    handleCheck (item) {
      let hasItem = false

      this.items = this.items.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    downloadExcel () {
      return this.$service.deliveryRequest.downloadAll(this.serverParams)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadDeliveryRequests(this.serverParams)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    openAddDeliveryRequests () {
      this.$refs.drImport.open()
    },
    loadDefaultItems () {
      this.onRequest({ pagination: {} })
    },
    resetStatusFilter () {
      const filter = (this.serverParams.filter || []).filter(value => value.field !== 'state')

      this.updateParams({ filter })
      this.loadDeliveryRequests(this.serverParams)
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleSearch (search) {
      this.updateParams({ search })

      return this.onRequest({ pagination: { page: 1, search } })
    },
    createNewDeliveryRequest () {
      this.$router.push('/outbound/shipments/entity/')
    },
    onRowClick (item) {
      this.setDeliveryRequest(item)
      this.$router.push('/outbound/shipments/entity/' + item.id)
    },
    onStatusFilter (values) {
      const query = {
        per_page: this.serverParams.perPage || 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          ...(this.serverParams.filter || [])
        ]
      }

      query.filter = query.filter.filter(val => {
        return val.type !== 'in' && val.field !== 'state'
      })

      if (values.length > 0) {
        query.filter.push({ type: 'in', field: 'state', values })
      }

      this.updateParams(query)
      this.loadDeliveryRequests(query)
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    }
  }
}
</script>

<style>
@media (min-width: 767px) {
  .d-lg-none {
    display: none !important;
  }
}
</style>
