<template>
  <q-tr
    v-if="data"
    :props="data"
  >
    <q-td
      key="extId"
      :props="data"
      auto-width
    >
      <div>
        {{ data.row.extId }}

        <q-popup-edit
          :model-value="data.row.extId"
          :title="$t('Edit extId')"
        >
          <q-input
            v-model="data.row.extId"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </div>
    </q-td>

    <q-td
      key="recipient"
      :props="data"
      auto-width
    >
      <h6 class="q-ma-none">
        {{ data.row.name }}

        <q-popup-edit
          :model-value="data.row.name"
          :title="$t('Edit name')"
        >
          <q-input
            v-model="data.row.name"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </h6>

      <strong>
        {{ data.row.phone }}

        <q-popup-edit
          :model-value="data.row.phone"
          :title="$t('Edit phone')"
        >
          <q-input
            v-model="data.row.phone"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </strong>

      <p class="q-ma-none">
        {{ data.row.email }}

        <q-popup-edit
          :model-value="data.row.email"
          :title="$t('Edit email')"
        >
          <q-input
            v-model="data.row.email"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </p>
    </q-td>

    <q-td
      key="address"
      :props="data"
      auto-width
    >
      <h6 class="q-ma-none">
        <span v-if="data.row.recipientLocality">
          {{ data.row.postcode + ' - ' + getTitleOfObject(data.row.recipientLocality) }}
        </span>

        <span v-else>
          {{ $t('No locality') }}
        </span>

        <q-popup-edit
          :model-value="data.row.recipientLocality"
          :title="$t('Edit locality')"
        >
          <form-builder :schema="localitySchema" />
        </q-popup-edit>
      </h6>

      <strong>
        <span v-if="data.row.street">
          {{ data.row.street + ' ' + data.row.house }}
        </span>

        <span v-else>
          {{ $t('No street') }}
        </span>

        <q-popup-edit
          :model-value="data.row.street"
          :title="$t('Edit street')"
        >
          <q-input
            v-model="data.row.street"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </strong>

      <p class="q-ma-none">
        {{ data.row.house }}

        <q-popup-edit
          :model-value="data.row.house"
          :title="$t('Edit house')"
        >
          <q-input
            v-model="data.row.house"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </p>
    </q-td>

    <q-td
      key="payment"
      :props="data"
      auto-width
    >
      <div>
        {{ data.row.payment || 0 }}

        <q-popup-edit
          :model-value="data.row.payment"
          :title="$t('Edit estimated cost')"
        >
          <q-input
            v-model="data.row.payment"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </div>
    </q-td>

    <q-td
      key="estimatedCost"
      :props="data"
      auto-width
    >
      <div>
        {{ data.row.estimatedCost || 0 }}

        <q-popup-edit
          :model-value="data.row.estimatedCost"
          :title="$t('Edit estimated cost')"
        >
          <q-input
            v-model="data.row.estimatedCost"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </div>
    </q-td>

    <q-td
      key="params"
      :props="data"
      auto-width
    >
      <h6 class="q-ma-none">
        {{ $t('Sender') + ' - ' + getTitleOfObject(data.row.sender) }}
      </h6>

      <strong>
        {{ $t('Rate') + ' - ' + getTitleOfObject(data.row.rate) }}
      </strong>

      <p class="q-ma-none">
        {{ $t('Service point') + ' - ' + getTitleOfObject(data.row.servicePoint) }}
      </p>

      <q-popup-edit
        :model-value="data.row.sender"
        :title="$t('Edit params')"
      >
        <form-builder ref="formBuilder" :schema="paramsSchema" />
      </q-popup-edit>
    </q-td>

    <q-td
      key="items"
      :props="data"
      auto-width
    >
      <div
        v-for="place in data.row.places"
        :key="place.extId"
      >
        <strong>{{ $t('Place') + ': ' + place.extId }}</strong>

        <table class="table table-valign-middle">
          <thead>
            <tr>
              <th>{{ $t('ExtId') }}</th>

              <th>{{ $t('Name') }}</th>

              <th>{{ $t('Payment') }}</th>

              <th>{{ $t('Estimated Cost') }}</th>

              <th>{{ $t('Count') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in place.items"
              :key="item.extId"
            >
              <td>{{ item.extId }}</td>

              <td>{{ item.name }}</td>

              <td>{{ item.payment }}</td>

              <td>{{ item.estimatedCost }}</td>

              <td>{{ item.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </q-td>

    <q-td
      key="actions"
      :props="data"
      auto-width
    >
      <q-btn
        color="light-blue-9"
        text-color="white"
        size="sm"
        :label="$t('Save')"
        no-caps
        unelevated
        @click="save(data.row)"
      />
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Helpers
import { generateDeliveryRequestFromTable } from '../../helpers/helpers'

export default {
  name: 'OrderImportRow',
  emits: ['change', 'submit'],
  props: {
    tableProps: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: null,
      isFocusedPostcode: false
    }
  },
  computed: {
    ...mapGetters([
      'deliveryRequests',
      'postcodesLoading',
      'appOptions'
    ]),
    paramsSchema () {
      return {
        groups: [
          {
            styleClasses: 'row locality-min-width',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'sender',
                value: this.data.row.sender,
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: sender => {
                  this.$emit('change', { ...this.data.row, sender })
                }
              },
              {
                ref: 'rate',
                type: 'multiselect',
                label: this.$t('Rate'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'rate',
                value: this.data.row.rate,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row._embedded || !row._embedded.deliveryService) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'deliveryService', direction: 'desc' },
                      { type: 'field', field: 'name', direction: 'desc' }
                    ],
                    filter: []
                  }

                  return this.$service.deliveryServiceRate.getAll(query)
                },
                onChange: (rate) => {
                  const data = {
                    ...this.data.row,
                    rate,
                    servicePoint: null
                  }

                  this.$emit('change', data)
                }
              },
              {
                ref: 'servicePoint',
                type: 'multiselect',
                label: this.$t('Service point'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'servicePoint',
                value: this.data.row.servicePoint,
                disabled: !this.data.row.rate || typeof this.data.row.rate === 'string' || (typeof this.data.row.rate === 'object' && !this.data.row.rate.id),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                description: (row) => {
                  if (!row) {
                    return ''
                  }

                  return `${row.rawAddress}, ${this.$t('extId')}: ${row.extId}, ${this.$t('phone')}: ${row.phone || 'none'}`
                },
                imagePreview: (row) => {
                  if (!row || !row._embedded || !row._embedded.deliveryService) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (
                    this.data.row.rate &&
                    this.data.row.rate._embedded &&
                    this.data.row.rate._embedded.deliveryService
                  ) {
                    query.filter.push(this.createDeliveryServiceFilter(this.data.row.rate._embedded.deliveryService))
                  }

                  if (this.data.row.locality && typeof this.data.row.locality === 'object') {
                    query.filter.push({
                      type: 'eq',
                      field: 'locality',
                      value: this.data.row.locality.id
                    })
                  }

                  return this.$service.servicePoint.getAll(query)
                },
                onChange: servicePoint => {
                  this.$emit('change', { ...this.data.row, servicePoint })
                }
              }
            ]
          }
        ]
      }
    },
    localitySchema () {
      return {
        groups: [
          {
            styleClasses: 'row locality-min-width',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Postcode'),
                field: 'postcode',
                value: this.data.row.postcode,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onFocus: e => {
                  this.isFocusedPostcode = true
                },
                onFocusOut: e => {
                  this.isFocusedPostcode = false
                },
                onInput: postcode => {
                  this.$emit('change', { ...this.data.row, postcode })
                  this.loadLocalitiesByPostcode(postcode)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Location'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'recipientLocality',
                value: this.data.row.recipientLocality,
                disabled: this.isFocusedPostcode || this.postcodesLoading,
                required: true,
                minimumLength: 3,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.type + ' ' + row.name + (row.postcode ? ', ' + row.postcode : '') + (row._embedded && row._embedded.area.name ? ' (' + row._embedded.area.name + ')' : '')
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: recipientLocality => {
                  this.$emit('change', { ...this.data.row, recipientLocality })
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    tableProps (value) {
      this.data = value
    }
  },
  mounted () {
    this.data = this.tableProps
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setDeliveryRequests'
    ]),
    getTitleOfObject (obj) {
      if (!obj) {
        return this.$t('No data')
      }

      return typeof obj === 'object'
        ? `${obj.name} (${obj.id})`
        : obj
    },
    save (row) {
      const item = generateDeliveryRequestFromTable(row)

      return this.$service.deliveryRequest.save(item)
        .then(item => {
          this.setDeliveryRequests([item, ...this.deliveryRequests])
          this.$emit('submit', item)
        })
    },
    loadLocalitiesByPostcode (value) {
      if (value.length < 4) {
        return
      }

      const query = {
        page: 1,
        per_page: 1,
        filter: [
          { type: 'eq', field: 'extId', value }
        ]
      }

      return this.$service.postcode.getAll(query)
        .then(({ items }) => {
          if (items.length === 0) {
            this.addErrorNotification('No localities found!')
            return
          }

          this.$emit('change', { ...this.data.row, postcode: value, recipientLocality: items[0]._embedded.locality })
        })
    },
    createDeliveryServiceFilter (deliveryService) {
      if (deliveryService.id) {
        return { type: 'eq', field: 'deliveryService', value: deliveryService.id }
      }

      const link = deliveryService._links.self.href
      const index = link.lastIndexOf('/')
      const value = link.slice(index + 1)

      return { type: 'eq', field: 'deliveryService', value }
    }
  }
}
</script>
