<template>
  <q-dialog v-model="isOpenDeliveryRequestsModal">
    <q-card
      v-if="isLoading"
      class="row items-center justify-center"
      style="min-width: 70vw; min-height: 300px;"
    >
      <div class="text-center">
        <q-spinner
          color="primary"
          size="4rem"
          class="q-mb-md"
        />

        <div class="text-center text-subtitle1 text-bold q-py-sm">
          {{ `${loading} ${$t('of')} ${totalLoadings}` }}
        </div>
      </div>
    </q-card>

    <q-card
      v-else
      style="min-width: 70vw;"
    >
      <q-card-section class="row items-center q-pb-none">
        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section>
        <div
          class="text-center"
          :class="items.length > 0 ? 'row items-center justify-center' : ''"
        >
          <q-btn
            :label="$t('Browse file on your computer')"
            size="md"
            :style="items.length <= 0 ? 'min-height: 5rem;' : ''"
            color="light-blue-9"
            no-caps
            @click="handleBrowse"
          />

          <div class="row items-center justify-center q-my-md">
            <div
              style="text-decoration: underline;"
              class="clickable text-dark q-pa-md"
              @click="getExampleTable"
            >
              {{ $t('Download template') }}
            </div>

            <a
              :href="$instructions.deliveryRequest.import"
              class="clickable text-dark q-pa-md"
              target="blank"
            >
              {{ $t('Read instructions') }}
            </a>
          </div>

          <q-btn
            color="light-blue-9"
            text-color="white"
            size="md"
            :label="$t('Upload all products')"
            class="q-mr-sm"
            :disable="items.length <= 0"
            no-caps
            unelevated
            @click="saveAll"
          />
        </div>

        <q-file
          ref="fileInput"
          filled
          class="d-none"
          :label="$t('Pick File')"
          @update:model-value="handleTable"
        />

        <q-table
          v-if="items.length > 0"
          row-key="id"
          class="full-width"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: items.length }"
          virtual-scroll
        >
          <template v-slot:body="props">
            <delivery-request-import-row
              :table-props="props"
              @change="handleChange"
              @submit="handleSubmit"
            />
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import DeliveryRequestImportRow from './DeliveryRequestImportRow.vue'

export default {
  name: 'DeliveryRequestImport',
  components: {
    DeliveryRequestImportRow
  },
  data () {
    return {
      isOpenDeliveryRequestsModal: false,
      items: [],
      loading: 0,
      totalLoadings: 0,
      isLoading: false,
      columns: [
        {
          label: this.$t('ExtId'),
          name: 'extId',
          align: 'left'
        },
        {
          label: this.$t('Recipient'),
          name: 'recipient',
          align: 'left'
        },
        {
          label: this.$t('Address'),
          name: 'address',
          align: 'left'
        },
        {
          label: this.$t('Payment'),
          name: 'payment',
          align: 'left'
        },
        {
          label: this.$t('EstimatedCost'),
          name: 'estimatedCost',
          align: 'left'
        },
        {
          label: this.$t('Params'),
          name: 'params',
          align: 'left'
        },
        {
          label: this.$t('Products'),
          name: 'items',
          align: 'left'
        },
        {
          label: this.$t('Actions'),
          name: 'actions',
          align: 'left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'deliveryRequests'
    ])
  },
  methods: {
    ...mapMutations([
      'setDeliveryRequests'
    ]),
    handleBrowse () {
      this.$refs.fileInput.pickFiles()
    },
    handleChange (update) {
      this.items = this.items.map(dr => {
        return dr.id === update.id
          ? update
          : dr
      })
    },
    open () {
      this.isOpenDeliveryRequestsModal = true
    },
    saveAll () {
      this.loading = 1
      this.totalLoadings = this.items.length
      this.isLoading = true

      return this.$service.import
        .processQueueWithDeliveryRequests(
          this.items,
          () => {
            this.loading += 1
          }
        )
        .then(newItems => {
          this.items = []
          this.setDeliveryRequests([
            ...newItems,
            ...this.deliveryRequests
          ])
        })
        .finally(() => {
          this.isLoading = false
          this.totalLoadings = 0
          this.loading = 0
        })
    },
    handleCloseDRModal () {
      this.isOpenDeliveryRequestsModal = false
    },
    getExampleTable () {
      return this.$service.import.getDRExample()
    },
    handleTable (file) {
      if (!file) {
        return
      }

      return this.$service.import.loadFile(file, 'deliveryRequests')
        .then(result => {
          this.totalLoadings = result.length
          this.loading = 1
          this.isLoading = true

          return this.$service.import.loadAddress(
            result,
            'recipientLocality',
            () => {
              this.loading += 1
            }
          )
        })
        .then(items => {
          this.totalLoadings = 0
          this.loading = 0
          this.isLoading = false
          this.items = items
        })
    },
    handleSave (row) {
      return this.$service.import.saveDeliveryRequest(row)
        .then(item => {
          this.handleSubmit(row)
          this.setDeliveryRequests([item, ...this.deliveryRequests])
        })
    },
    handleSubmit (dr) {
      this.items = this.items.filter(d => d.extId !== dr.extId)
    }
  }
}
</script>
